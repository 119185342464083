<template>
  <div class="p-3">
    <h3>Proses Perpindahan Naungan dari Sekolah</h3>
    <hr class="p-0 m-0" />
    <h3>{{ detailMoveNaungan.schoolName }}</h3>
    <label for="select_pengawas">Ke Pengawas</label>
    <el-select
      id="select_pengawas"
      :loading="manajemenPengawas.loadingAllData"
      v-model="detailMoveNaungan.selectPengawasDestination"
      aria-autocomplete="none"
      class="w-100"
      filterable
      placeholder="Filter Kota/Kab"
    >
      <el-option label="Pilih Pengawas" value=""></el-option>
      <el-option
        v-for="(item, index) in pengawasAllDataComputed"
        :key="`city_${index}`"
        :label="item.name"
        :value="item.id"
      >
        <span style="float: left">{{ item.name }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">{{
          item.countNaungan
        }}</span>
      </el-option>
    </el-select>
    <br />
    <br />
    <button
      v-if="detailMoveNaungan.selectPengawasDestination"
      v-b-tooltip.hover
      class="btn btn-success btn-sm text-white"
      title="Proses"
      @click="prosesMoveNaungan"
    >
      Proses
    </button>
    <button
      class="btn btn-secondary btn-sm"
      @click="() => $emit('close-modal')"
    >
      Batal
    </button>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ModalMoveNaungan",
  props: ["onClose", "jenisModal", "fromParent"],
  computed: {
    ...mapState({
      detailMoveNaungan: state =>
        state.manajemenPengawas.data.detailMoveNaungan,
      manajemenPengawas: state => state.manajemenPengawas.data
    }),
    pengawasAllDataComputed() {
      return this.manajemenPengawas.itemsAll
        .filter(item => item.id !== this.detailMoveNaungan.pengawasOldId)
        .map(item => {
          return {
            id: item.id,
            name: item.name,
            countNaungan: item.schools.length + " Sekolah"
          };
        });
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("manajemenPengawas", [
      "getManajemenPengawasAllData",
      "postMoveNaungan"
    ]),
    ...mapActions("schools", [
      "getSchools"
    ]),
    ...mapMutations("manajemenPengawas", [
      "removeArrayIndexDetailPengawasSchools"
    ]),

    async prosesMoveNaungan() {
      const result = await this.postMoveNaungan({
        uuidSchool: this.detailMoveNaungan.schoolUuId,
        source_id: this.detailMoveNaungan.pengawasOldId,
        destination_id: this.detailMoveNaungan.selectPengawasDestination
      });
      if (result) {
        if (this.fromParent==="naunganSchools") {
          this.removeArrayIndexDetailPengawasSchools(this.detailMoveNaungan.schoolIndex);
        } else {
          this.getSchools();
        }
        this.$emit("close-modal");
      }
    }
  },
  mounted() {
    this.getManajemenPengawasAllData();
  },
  beforeDestroy() {
    this.$store.commit(
      "manajemenPengawas/changeDetailMoveNaunganManajemenPengawas",
      {
        selectPengawasDestination: "",
        schoolIndex: "",
        schoolName: "",
        schoolUuId: "",
        schoolId: ""
      }
    );
  },
  watch: {}
};
</script>
