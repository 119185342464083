<template>
  <div>
    <FilterData />

    <div class="row d-flex mb-2">
      <LabelCountData
        :count-items.sync="schools.items.length"
        :count-items-all.sync="schools.total"
      />
      <PaginationData
        :current-page.sync="schools.page"
        :last-page.sync="schools.lastPage"
        @change-nextpage="onNextPageEventHandler(schools.page, getSchools)"
        @change-prevpage="onPrevPageEventHandler(schools.page, getSchools)"
      />
    </div>

    <div class="row mt-n2 ml-0 mr-0">
      <b-table
        :busy="schools.loading"
        :fields="fields"
        :items="schoolsComputed"
        :no-border-collapse="noCollapse"
        :sticky-header="false"
        class="table-kertas-kerja"
        hover
        responsive
        show-empty
        striped
        ref="selectableTable"
      >
        <template #table-busy>
          <EfekLoading />
        </template>

        <template #empty>
          <LabelEmpty />
        </template>

        <template #head(selected)>
          <b-button-group>
            <b-dropdown right text="Pilih" size="sm">
              <b-dropdown-item @click="onCheckAllEventHandler"
                >Pilih Semua
              </b-dropdown-item>
              <b-dropdown-item @click="onUncheckAllEventHandler"
                >Hapus Pilihan
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </template>

        <template #cell(selected)="data">
          <div v-if="data.item.government.length === 0">
            <input
              v-model="data.item.isSelected"
              type="checkbox"
              @change="onCheckboxChange(data.item)"
            />
          </div>
        </template>

        <template #cell(no)="data">
          <span>{{ data.index + 1 }}</span>
        </template>

        <template #cell(name)="data">
          <span>{{ data.item.name }}</span> <br>
          <el-tag
            size="small"
            type="success"
          >
            {{ data.item.type.toUpperCase() }}
          </el-tag>
          &nbsp;
          <el-tag
            size="small"
            :type="
              data.item.type_school.toLowerCase() === 'swasta' ? 'info' : ''
            "
          >
            {{ data.item.type_school.toLowerCase() }}
          </el-tag>
        </template>

        <template #cell(city)="data">
          <span>{{ data.item.city_name }}</span>
          <br />
          <span>{{ data.item.province_name }}</span>
        </template>

        <template #cell(havePengawas)="data">
          <div v-if="data.item.government.length">
            <a
              v-b-tooltip.hover
              title="Detail Pengawas"
              :href="
                `/pemerintah/manajemen-pengawas/${data.item.government[0].id}`
              "
            >
              {{ data.item.government[0].name }}
            </a>
          </div>
          <span v-else>-</span>
        </template>

        <template v-slot:cell(aksi)="data">
          <button
            v-if="data.item.government.length"
            v-b-tooltip.hover
            class="btn btn-warning btn-sm text-white"
            title="Pindah Binaan Sekolah"
            @click="onMoveSchoolToOtherPengawas(data.item, data.index)"
          >
            <i class="fas fa-arrows-alt"></i>
          </button>
        </template>
      </b-table>
    </div>

    <Modal
      :onClose="() => (showmodal = false)"
      :show="showmodal"
      title="Tambah Data"
      width="50%"
    >
      <ModalMoveNaungan
        v-if="showmodal"
        from-parent="schools"
        :onClose="() => (showmodal = false)"
        @close-modal="showmodal = false"
      />
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import LabelCountData from "@/components/Table/LabelCountData.vue";
import EfekLoading from "@/components/Table/EfekLoading.vue";
import LabelEmpty from "@/components/Table/LabelEmpty.vue";
import Modal from "@/components/Modal.vue";
import PaginationData from "@/components/Table/PaginationData.vue";
import FilterData from "@/views/Goverment/ManajemenPengawas/Schools/Filter/FilterData.vue";
import {
  onNextPageEventHandler,
  onPrevPageEventHandler
} from "@/services/globalService";
import ModalMoveNaungan from "@/views/Goverment/ManajemenPengawas/Detail/Table/ModalMoveNaungan.vue";

export default {
  name: "TableSchools",
  components: {
    Modal,
    ModalMoveNaungan,
    FilterData,
    PaginationData,
    LabelEmpty,
    EfekLoading,
    LabelCountData
  },
  data() {
    return {
      fields: [
        {
          key: "no",
          sortable: false,
          width: "10px",
          thStyle: {
            width: "10%"
          }
        },
        {
          label: "Pilih",
          key: "selected",
          sortable: false,
          width: "10px",
          thStyle: {
            width: "5%"
          }
        },
        {
          label: "Sekolah",
          key: "name",
          sortable: false,
          thStyle: {
            width: "50%"
          }
        },
        {
          label: "Kota/Kab.",
          key: "city",
          sortable: false,
          thStyle: {
            width: "15%"
          }
        },
        {
          label: "Pengawas",
          key: "havePengawas",
          sortable: false,
          thStyle: {
            width: "35%"
          }
        },
        {
          key: "aksi",
          sortable: false,
          width: "150px",
          minWidth: "100px",
          thStyle: {
            width: "20%"
          }
        }
      ],
      stickyHeader: true,
      showmodal: false,
      noCollapse: true
    };
  },
  computed: {
    ...mapState({
      schools: state => state.schools.data,
      detailPengawas: state => state.manajemenPengawas.data.detailPengawas
    }),

    schoolsComputed() {
      return this.schools.items.map(school => {
        return {
          ...school,
          isSelected: this.schools.selects.includes(school.id)
        };
      });
    },

    arraySchoolIdsComputed() {
      return this.schoolsComputed.map(school => school.id);
    }
  },
  methods: {
    onPrevPageEventHandler,
    onNextPageEventHandler,
    ...mapActions("schools", ["getSchools"]),
    ...mapMutations("schools", ["changeSchools"]),

    async onMoveSchoolToOtherPengawas(item, index) {
      const employee = item.government[0];
      this.showmodal = true;
      this.$store.commit(
        "manajemenPengawas/changeDetailMoveNaunganManajemenPengawas",
        {
          pengawasOldId: employee.id || 0,
          selectPengawasDestination: "",
          schoolIndex: index,
          schoolName: item.name,
          schoolId: item.row_id,
          schoolUuId: item.id
        }
      );
    },

    onUncheckAllEventHandler() {
      const reformatData = this.schools.selects.filter(
        id => !this.arraySchoolIdsComputed.includes(id)
      );
      return (this.schools.selects = reformatData);
    },
    onCheckAllEventHandler() {
      const dataSchoolId = this.schoolsComputed
        .filter(school => !school.isSelected && school.government.length === 0)
        .map(school => school.id);
      const merge = [...this.schools.selects, ...dataSchoolId];
      this.changeSchools({
        selects: merge
      });
    },

    onCheckboxChange(school) {
      const index = this.schools.selects.indexOf(school.id);
      if (school.isSelected) {
        // Checkbox is checked, add school ID to schools.selects
        if (index === -1) {
          this.schools.selects.push(school.id);
        }
      } else {
        // Checkbox is unchecked, remove school ID from schools.selects
        if (index !== -1) {
          this.schools.selects.splice(index, 1);
        }
      }
    }
  },
  mounted() {},
  watch: {}
};
</script>
<style lang="scss"></style>
