<script>
export default {
  name: "LabelCountData",
  props: {
    countItems: {
      type: Number,
      required: true
    },
    countItemsAll: {
      type: Number,
      required: true
    },
  }
}
</script>

<template>
  <div class="col-sm-6">
    <h5 class="mt-2">
      {{ countItems }} dari {{ countItemsAll }} data Ditampilkan
    </h5>
  </div>
</template>

<style scoped lang="scss">

</style>