<template>
  <div class="form-inline d-inline">
    <b-badge
      v-for="(role, index) in rolesToShow"
      :key="`role_${index}`"
      variant="success"
      class="mr-1 text-with"
    >
      {{ role }}
    </b-badge>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RolesBadge",
  props: {
    rolesInput: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["rolesArrayGetters"]),

    rolesToShow() {
      return this.rolesInput.length ? this.rolesInput : this.rolesArrayGetters;
    }
  }
};
</script>
