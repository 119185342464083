<template>
  <div>
    <div class="row d-flex mb-2">
      <div class="col-sm col-12">
        <input
          v-model="manajemenPengawas.filter"
          class="form-control"
          placeholder="Cari Nama Pengawas"
        />
      </div>
      <div class="col-sm-3 text-right ml-auto">
        <el-select
          v-model="manajemenPengawas.filterStatus"
          aria-autocomplete="none"
          class="w-100"
          placeholder="Pilih Status"
          @change="getManajemenPengawas"
        >
          <el-option label="Semua Pengawas" value=""></el-option>
          <el-option
            v-for="(item, index) in ['Belum Memiliki Sekolah']"
            :key="`status_is_${index}`"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="row d-flex mb-2">
      <LabelCountData
        :count-items-all="manajemenPengawas.total"
        :count-items="manajemenPengawas.items.length"
      />
      <PaginationData
        :current-page="manajemenPengawas.page"
        :last-page="manajemenPengawas.lastPage"
        @change-nextpage="
          onNextPageEventHandler(manajemenPengawas.page, getManajemenPengawas)
        "
        @change-prevpage="
          onPrevPageEventHandler(manajemenPengawas.page, getManajemenPengawas)
        "
      />
    </div>

    <div class="row mt-n2 ml-0 mr-0">
      <b-table
        :busy="manajemenPengawas.loading"
        :fields="fields"
        :items="manajemenPengawas.items"
        :no-border-collapse="noCollapse"
        :sticky-header="false"
        class="table-manajemen-pengawas"
        :sort-by.sync="manajemenPengawas.sort_field"
        :sort-desc.sync="manajemenPengawas.sort_dir"
        hover
        responsive
        show-empty
        striped
      >
        <template #table-busy>
          <EfekLoading />
        </template>

        <template #empty>
          <LabelEmpty />
        </template>

        <template #cell(no)="data">
          <span>{{
            (manajemenPengawas.page - 1) * manajemenPengawas.limit +
              data.index +
              1
          }}</span>
        </template>

        <template #cell(name)="data">
          <span>{{ data.item.name }}</span>
          <p class="m-0 p-0 text-muted small">
            NIP: {{ data.item.nip }}
            <i
              class="fas fa-link cursor-pointer"
              @click="copyTeksInput(data.item.nip)"
            ></i>
            &nbsp;<RolesBadge :rolesInput="data.item.roles" />
          </p>
        </template>

        <template #cell(schools)="data">
          <span v-if="data.item.schools.length">
            {{ data.item.schools.length }} Sekolah ({{
              onlyTypeSchoolMethod(data.item.schools)
            }})
          </span>
          <span v-else>-</span>
        </template>

        <template v-slot:cell(aksi)="data">
          <router-link
            :to="{
              name: 'manajemenPengawasDetail',
              params: { employeeId: data.item.id }
            }"
          >
            <button
              v-b-tooltip.hover
              class="btn btn-warning btn-sm text-white"
              title="Detail Binaan Sekolah"
            >
              <i class="fas fa-eye"></i>
            </button>
          </router-link>
        </template>
      </b-table>
    </div>

    <Modal
      :onClose="() => (manajemenPengawas.showmodal = false)"
      :show="manajemenPengawas.showmodal"
      :title="
        manajemenPengawas.formData.id
          ? `Proses Update (Perubahan Data Agenda Surat)`
          : `Tambah Data Agenda Surat`
      "
      width="75%"
    >
      <ModalDetail
        v-if="manajemenPengawas.showmodal"
        :onClose="() => (manajemenPengawas.showmodal = false)"
        @closeModal="() => (manajemenPengawas.showmodal = false)"
      />
    </Modal>

    <Modal
      :onClose="() => (showmodalAdmin = false)"
      :show="showmodalAdmin"
      :title="
        manajemenPengawas.formData.id
          ? `Proses Update (Perubahan Data Admin)`
          : `Tambah Data Admin`
      "
      width="75%"
    >
      <ModalDetail
        v-if="showmodalAdmin"
        jenis-modal="Admin"
        :onClose="() => (showmodalAdmin = false)"
        @closeModal="() => (showmodalAdmin = false)"
      />
    </Modal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { copyTeksInput } from "@/utils/Api";
import moment from "moment";
import { _ } from "vue-underscore";
import LabelCountData from "@/components/Table/LabelCountData.vue";
import PaginationData from "@/components/Table/PaginationData.vue";
import EfekLoading from "@/components/Table/EfekLoading.vue";
import LabelEmpty from "@/components/Table/LabelEmpty.vue";
import {
  onNextPageEventHandler,
  onPrevPageEventHandler
} from "@/services/globalService";
import RolesBadge from "@/components/RolesBadge/index.vue";

export default {
  name: "TableManajemenPengawas",
  props: {
    searchSchoolType: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  components: {
    RolesBadge,
    LabelEmpty,
    EfekLoading,
    PaginationData,
    LabelCountData,
    Modal: () => import("@/components/ModalUI"),
    ModalDetail: () => import("./Modal/index.vue")
  },
  data() {
    return {
      fields: [
        {
          key: "no",
          sortable: false,
          thStyle: {
            width: "5%"
          }
        },
        {
          label: "Nama",
          key: "name",
          sortable: true
        },
        {
          label: "Sekolah",
          key: "schools",
          sortable: false,
          thStyle: {
            width: "30%"
          }
        },
        {
          key: "aksi",
          sortable: false,
          width: "10px",
          thStyle: {
            width: "10%"
          }
        }
      ],
      stickyHeader: true,
      noCollapse: true,
      showmodal: false,
      showmodalAdmin: false
    };
  },
  computed: {
    ...mapState({
      manajemenPengawas: state => state.manajemenPengawas.data,
      employee: state => state.employee.data
    }),
    ...mapGetters("employee", ["employeeGetters"]),
    ...mapGetters(["govermentIdGetters", "isRolesAdminGovermentGetters"]),

    yearComputed() {
      const currentYear = moment().year(); // Mendapatkan tahun saat ini
      const startYear = 2019;
      let years = [];

      for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    }
  },
  methods: {
    onPrevPageEventHandler,
    onNextPageEventHandler,
    ...mapActions("manajemenPengawas", ["getManajemenPengawas"]),

    copyTeksInput,
    onlyTypeSchoolMethod(schools) {
      const uniqueTypes = Array.from(new Set(schools.map(item => item.type)));
      return uniqueTypes.join(", ");
    }
  },
  mounted() {},
  watch: {
    "manajemenPengawas.filter": _.debounce(function() {
      this.getManajemenPengawas({page: 1});
    }, 1000),
    "manajemenPengawas.sort_dir": _.debounce(function() {
      this.getManajemenPengawas();
    }, 1000)
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/custom/_rkas-table.scss";
</style>
