<script>
export default {
  name: "EfekLoading",
  props: {
    label: {
      type: String,
      required: false,
      default: () => 'Loading'
    },
  }
}
</script>

<template>
  <div class="text-center text-muted my-2">
    <b-spinner class="align-middle"></b-spinner>
    <strong>{{label}}...</strong>
  </div>
</template>

<style scoped lang="scss">

</style>