<template>
  <div>
    <div class="row d-flex mb-2">
      <div class="col-sm col-12">
        <input
          v-model="pengawasNaungan.filter"
          class="form-control"
          placeholder="Cari Nama Pengawas"
        />
      </div>
    </div>

    <div class="row d-flex mb-2">
      <LabelCountData
        :count-items-all="handleDataItemsAllGetters.length"
        :count-items="handleDataItemsAllGetters.length"
      />
    </div>

    <div class="row mt-n2 ml-0 mr-0">
      <b-table
        :busy="pengawasNaungan.loading"
        :fields="fields"
        :items="handleDataItemsAllGetters"
        :no-border-collapse="noCollapse"
        :sticky-header="stickyHeader"
        class="table-manajemen-pengawas"
        :sort-by.sync="pengawasNaungan.sort_field"
        :sort-desc.sync="pengawasNaungan.sort_dir"
        hover
        responsive
        show-empty
        striped
      >
        <template #table-busy>
          <EfekLoading />
        </template>

        <template #empty>
          <LabelEmpty />
        </template>

        <template #cell(no)="data">
          <span>{{
            (pengawasNaungan.page - 1) * pengawasNaungan.limit +
              data.index +
              1
          }}</span>
        </template>

        <template #cell(name)="data">
          <span>{{ data.item.name }}</span><br>
          <small>{{data.item.city_name}} | {{data.item.type}} - {{data.item.type_school}}</small>

        </template>

        <template #cell(poin)="data">
          <span v-if="data.item.activeStorage.length">
            50
          </span>
          <span v-else class="text-decoration-line-through">50</span>
        </template>

        <template #cell(pivot)="data">
          <span>{{data.item.pivot.created_at|formatDateTime}}</span>
        </template>

        <template #cell(activeStorage)="data">
          <span v-if="data.item.activeStorage.length">
            {{data.item.activeStorage[0].expired_at}}
          </span>
          <el-tag v-else type="danger">Tidak Aktif</el-tag>
        </template>

        <template v-slot:cell(aksi)="data">
          <a
            :href="`https://wa.me/6281779205008?text=Hallo, Kak cs@diskola.id saya Pengawas ${scopeNameGetters}, mau menanyakan perihal Sekolah ${data.item.name} ...`"
            target="_blank">
            <button
              v-b-tooltip.hover
              class="btn btn-success btn-sm text-white"
              title="Kirim Whatsapp"
            >
              <i class="fab fa-whatsapp"></i>
            </button>
          </a>
        </template>
      </b-table>
    </div>

  </div>
</template>
<script>
import { copyTeksInput } from "@/utils/Api";
import LabelCountData from "@/components/Table/LabelCountData.vue";
import EfekLoading from "@/components/Table/EfekLoading.vue";
import LabelEmpty from "@/components/Table/LabelEmpty.vue";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "TablePengawasNaungan",
  props: {
    searchSchoolType: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  components: {
    LabelEmpty,
    EfekLoading,
    LabelCountData,
  },
  data() {
    return {
      fields: [
        { key: "no", sortable: false, width: "50px" },
        {
          label: "Sekolah",
          key: "name",
          sortable: false,
        },
        { label: "Poin", key: "poin", sortable: false },
        { label: "Binaan Sejak", key: "pivot", sortable: false },
        { label: "Aktif Hingga", key: "activeStorage", sortable: false },
        {
          key: "aksi",
          sortable: false,
          width: "10px",
          thStyle: {
            width: "10%"
          }
        }
      ],
      stickyHeader: true,
      noCollapse: true,
      showmodal: false,
      showmodalAdmin: false
    };
  },
  computed: {
    ...mapState({
      pengawasNaungan: state => state.pengawasNaungan.data
    }),
    ...mapGetters("pengawasNaungan", ["handleDataItemsAllGetters"]),
    ...mapGetters(["scopeNameGetters"]),
  },
  methods: {
    ...mapActions("pengawasNaungan", ["getSchoolsNaunganPengawas"]),

    copyTeksInput,
  },
  mounted() {
    if (!this.pengawasNaungan.itemsAll.length) {
      this.getSchoolsNaunganPengawas();
    }
  },
  watch: {
  }
};
</script>
<style lang="scss">
.text-decoration-line-through {
  text-decoration: line-through;
}
</style>
