<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { _ } from "vue-underscore";

export default {
  name: "FilterData",
  props: {
    childFrom: {
      type: String,
      default: "internal"
    },
    arrayRoles: {
      type: Array
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loadingAreaCoverage: state => state.employee.data.loadingAreaCoverage,
      itemsAreaCoverage: state => state.employee.data.itemsAreaCoverage,
      schools: state => state.schools.data,
      cities: state => state.cities.data
    }),
    ...mapGetters("schools", ["arrayTypeSchoolGetters"]),
    ...mapGetters(["provinceIdGetters"]),

    citiesComputed() {
      return this.itemsAreaCoverage.map(item => {
        return {
          name: item.name,
          uuid: item.uuid,
          id: item.id,
        }
      })
    }
  },
  methods: {
    ...mapActions("employee", ["getAreaCoverage"]),
    ...mapActions("cities", ["getCities"]),
    ...mapActions("schools", ["getSchools"])
  },
  watch: {
    "schools.filter": _.debounce(function() {
      this.getSchools({page: 1});
    }, 1000)
  },
  mounted() {
    this.getCities(this.provinceIdGetters);
    if (!this.itemsAreaCoverage.length) this.getAreaCoverage();
  }
};
</script>

<template>
  <div class="row d-flex mb-2">
    <div class="col-sm col-12">
      <input
        v-model="schools.filter"
        class="form-control"
        placeholder="Cari Nama Sekolah..."
      />
    </div>
    <div class="col-sm-3 text-right ml-auto">
      <el-select
        :loading="loadingAreaCoverage"
        v-model="schools.filterCity"
        aria-autocomplete="none"
        class="w-100"
        filterable
        placeholder="Filter Kota/Kab"
        @change="getSchools({page: 1})"
      >
        <el-option label="Semua" value=""></el-option>
        <el-option
          v-for="(item, index) in citiesComputed"
          :key="`city_${index}`"
          :label="item.name"
          :value="item.uuid"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col-sm">
      <label for="checkbox-1">
        <input
          id="checkbox-1"
          v-model="schools.filterNotPengawas"
          type="checkbox"
          @change="getSchools({page: 1})"
        />
        Tidak memiliki Pengawas
      </label>
    </div>
    <div class="col-sm-3 text-right ml-auto">
      <div class="clearfix">
        <div class="float-right">
          <el-select
            v-model="schools.limit"
            aria-autocomplete="none"
            class="w-50"
            placeholder="Tampilkan Data"
            @change="getSchools({page: 1})"
          >
            <el-option
              v-for="item in schools.listLimit"
              :key="`limit_${item}`"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="
              arrayTypeSchoolGetters.length >= 2 &&
                schools.filterChildFrom === 'detailPengawas'
            "
            v-model="schools.filterType"
            aria-autocomplete="none"
            class="w-50"
            placeholder="Filter Tipe"
            @change="getSchools({page: 1})"
          >
            <el-option label="Semua" value=""></el-option>
            <el-option
              v-for="item in arrayTypeSchoolGetters"
              :key="`type_${item}`"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
