<template>
  <div>
    <div class="row d-flex mb-2">
      <div class="col-sm">
        <input
          v-model="employee.filter"
          class="form-control"
          placeholder="Cari Name, NIP"
        />
      </div>
      <div class="col-sm-3 text-right ml-auto">
        <el-select
          v-model="employee.filterRoles"
          aria-autocomplete="none"
          class="w-100"
          multiple
          placeholder="Filter Roles"
          @change="getEmployee"
        >
          <el-option
            v-for="(item,index) in employee.listRoles"
            :key="`roles_${index}`"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-sm-4 text-right ml-auto">
        <b-dropdown id="period" block variant="warning">
          <template slot="button-content">
            Tambah
            <i class="uil uil-angle-down"></i>
          </template>
          <b-dropdown-item
            v-for="(item, index) in ['Pengawas', 'Admin']"
            :key="index"
            :value="item"
            @click="addEmployee(item)"
            >{{ item }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="row d-flex mb-2">
      <div class="col-sm-6">
        <h5 class="mt-2">
          {{ employeeGetters.length }} dari {{ employee.total }} data Hak Akses
          Ditampilkan
        </h5>
      </div>

      <!--<div class="col-sm-6 text-right ml-auto">
        <label class="mr-2 mt-2">{{ employee.page }} / {{ employee.lastPage }}</label>
        <button
          :disabled="currentPage <= 1"
          class="btn btn-outline-primary btn-pagination btn-sm pr-3"
          @click="() => currentPage&#45;&#45;"
        >
          <span class="ml-1"><i class="fas fa-angle-left"></i></span>
        </button>
        <button
          :disabled="currentPage >= totalPage"
          class="btn btn-outline-primary btn-pagination btn-sm pl-3"
          @click="() => currentPage++"
        >
          <span class="mr-1"><i class="fas fa-angle-right"></i></span>
        </button>
      </div>-->
    </div>
    <div class="row mt-n2 ml-0 mr-0">
      <b-table
        :busy="employee.loading"
        :fields="fields"
        :items="employeeGetters"
        :no-border-collapse="noCollapse"
        :sticky-header="false"
        class="table-kertas-kerja"
        hover
        responsive
        show-empty
        striped
      >
        <template #table-busy>
          <div class="text-center text-muted my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #empty>
          <p class="text-center">Tidak ditemukan data yang sesuai.</p>
        </template>

        <template #cell(no)="data">
          <span>{{ data.index + 1 }}</span>
          <!--  <span>{{ (currentPage - 1) * perPage + data.index + 1 }}</span>-->
        </template>

        <template #cell(name)="data">
          <span>{{ data.item.name }}</span
          ><br />
          <span>
            NIP:{{ data.item.nip }}
            <i
              class="fas fa-link cursor-pointer"
              @click="copyTeksInput(data.item.nip)"
            ></i>
          </span>
        </template>

        <template #cell(email)="data">
          <span>
            {{ data.item.email }}
            <i
              class="fas fa-link cursor-pointer"
              @click="copyTeksInput(data.item.email)"
            ></i>
          </span>
        </template>

        <template #cell(phone)="data">
          <span>{{ data.item.phone }}</span>
        </template>

        <template #cell(roles)="data">
          <span>
            <RolesBadge :roles-input="data.item.roles" />
            <span v-if="data.item.roles[0] === 'Admin'">
              Cabang
              {{
                (data.item.city && data.item.city.name) || "-"
              }}
            </span>
          </span>
        </template>

        <template v-slot:cell(aksi)="data">
          <button
            v-b-tooltip.hover
            class="btn btn-warning btn-sm text-white"
            title="Edit Data"
            @click="editData(data.item)"
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
          <button
            v-b-tooltip.hover
            class="btn btn-danger btn-sm text-white"
            title="Hapus Data"
            @click="deleteToken(data.item.id)"
          >
            <i class="fas fa-trash"></i>
          </button>
          <button
            v-b-tooltip.hover
            class="btn btn-danger btn-sm text-white"
            title="Reset Password"
            @click="resetPassword(data.item.id)"
          >
            <i class="fas fa-unlock-alt"></i>
          </button>
        </template>
      </b-table>
    </div>

    <Modal
      :onClose="() => (showmodal = false)"
      :show="showmodal"
      :title="
        employee.formData.id ? `Proses Update (Perubahan Data)` : `Tambah Data`
      "
      width="75%"
    >
      <ModalDetail
        v-if="showmodal"
        :onClose="() => (showmodal = false)"
        jenis-modal="Pengawas"
        @closeModal="() => (showmodal = false)"
      />
    </Modal>

    <Modal
      :onClose="() => (showmodalAdmin = false)"
      :show="showmodalAdmin"
      :title="
        employee.formData.id
          ? `Proses Update (Perubahan Data Admin)`
          : `Tambah Data Admin`
      "
      width="75%"
    >
      <ModalDetail
        v-if="showmodalAdmin"
        jenis-modal="Admin"
        :onClose="() => (showmodalAdmin = false)"
        @closeModal="() => (showmodalAdmin = false)"
      />
    </Modal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { copyTeksInput } from "@/utils/Api";
import RolesBadge from "@/components/RolesBadge/index.vue";

export default {
  name: "TableEmployee",
  props: {
    searchSchoolType: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  components: {
    RolesBadge,
    Modal: () => import("@/components/ModalUI"),
    ModalDetail: () => import("./Modal/index.vue")
  },
  computed: {
    ...mapState({
      employee: state => state.employee.data,
      cities: state => state.cities.data
    }),
    ...mapGetters("employee", ["employeeGetters"])
  },
  data() {
    return {
      fields: [
        { key: "no", sortable: false, width: "50px" },
        { key: "name", sortable: false, width: "150px", minWidth: "100px" },
        { key: "email", sortable: false },
        { key: "phone", sortable: false },
        { key: "roles", sortable: false },
        { key: "aksi", sortable: false, width: "150px", minWidth: "100px" }
      ],
      stickyHeader: true,
      noCollapse: true,
      showmodal: false,
      showmodalAdmin: false
    };
  },
  methods: {
    ...mapActions("employee", [
      "getEmployee",
      "actionRead",
      "deleteEmployee",
      "actionEdit",
      "actionCreate"
    ]),
    ...mapMutations("employee", ["changeFormDataEmployee", "changeEmployee", "changeFormDataEmployeeObjectAssign"]),
    ...mapActions("cities", ["getCities"]),
    copyTeksInput,
    addEmployee(jenis) {
      this.actionCreate();
      if (jenis === "Pengawas") {
        this.showmodal = true;
        this.showmodalAdmin = false;
        return true;
      }
      this.changeFormDataEmployeeObjectAssign({password: this.employee.passwordAdmin})
      this.showmodal = false;
      this.showmodalAdmin = true;
    },
    deleteToken(id) {
      this.$confirm("Anda yakin menghapus akun ini ?", "Konfirmasi", {
        confirmButtonText: "Ya",
        cancelButtonText: "Batalkan",
        type: "warning"
      })
        .then(async () => {
          await this.deleteEmployee({ id: id });
        })
        .catch(e => e);
    },
    resetPassword(id) {
      this.$confirm("Anda yakin mereset password akun ini ?", "Konfirmasi", {
        confirmButtonText: "Ya",
        cancelButtonText: "Batalkan",
        type: "warning"
      })
        .then(async () => {
          console.log(id);
          this.$message("PROSES PENGEMBANGAN...");
        })
        .catch(e => e);
    },
    editData(row) {
      const payload = {
        id: row.id,
        nip: row.nip,
        name: row.name,
        email: row.email,
        phone: row.phone,
        government_id: row.department.id,
        roles: row.roles
      }
      const payload2 = { detailFormData: row, showmodal: true }

      if (row.roles[0] === 'Admin') {
        payload.city_id = row.city.id;
        payload2.showmodal = false;
        payload2.showmodalAdmin = true;
        this.showmodal = false;
        this.showmodalAdmin = true;
      } else {
        this.showmodal = true;
        this.showmodalAdmin = false;
        payload2.showmodal = true;
        payload2.showmodalAdmin = false;
      }
      this.changeFormDataEmployee(payload);
      this.changeEmployee(payload2);
      this.actionEdit();
    }
  },
  mounted() {
    this.actionRead();
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/custom/_rkas-table.scss";
</style>
