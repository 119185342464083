<script>
export default {
  name: "PaginationData",
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    lastPage: {
      type: Number,
      required: true
    }
  },
  methods: {
    emitChangeNextPage(newPage) {
      this.$emit('change-nextpage', newPage);
    },
    emitChangePrevPage(newPage) {
      this.$emit('change-prevpage', newPage);
    },
  }

}
</script>

<template>
  <div class="col-sm-6 text-right ml-auto">
    <label class="mr-2 mt-2">{{ currentPage }} / {{ lastPage }}</label>
    <button
      :disabled="currentPage <= 1"
      class="btn btn-outline-primary btn-pagination btn-sm pr-3"
      @click="emitChangePrevPage(currentPage - 1)"
    >
      <span class="ml-1"><i class="fas fa-angle-left"></i></span>
    </button>
    <button
      :disabled="currentPage >= lastPage"
      class="btn btn-outline-primary btn-pagination btn-sm pl-3"
      @click="emitChangeNextPage(currentPage + 1)"
    >
      <span class="mr-1"><i class="fas fa-angle-right"></i></span>
    </button>
  </div>
</template>

<style scoped lang="scss">

</style>