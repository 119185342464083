<template>
  <div>
    <div class="row d-flex mb-2">
      <div class="col-sm col-12">
        <h3 class="m-0 p-0">
          {{ nameOfDetailPengawas }}
          <RolesBadge :roles-input="rolesOfDetailPengawas" />
        </h3>
        <p class="m-0 p-0">
          NIP: {{ nipOfDetailPengawas }} NO.HP: {{ phoneOfDetailPengawas }}
        </p>
      </div>
    </div>

    <div class="row d-flex mb-2">
      <LabelCountData
        :count-items-all="countNaunganComputed"
        :count-items="countNaunganComputed"
      />
    </div>

    <div class="row mt-n2 ml-0 mr-0">
      <b-table
        :busy="detailPengawas.loading"
        :fields="fields"
        :items="schoolsOfPengawasGetters"
        :no-border-collapse="noCollapse"
        :sticky-header="false"
        class="table-kertas-kerja font_small"
        hover
        responsive
        show-empty
        striped
      >
        <template #table-busy>
          <EfekLoading />
        </template>

        <template #empty>
          <p class="text-center">Belum Memiliki Sekolah.</p>
        </template>

        <template #cell(no)="data">
          <span>{{ data.index + 1 }}</span>
        </template>

        <template #cell(name)="data">
          <span>{{ data.item.name }}</span>
          <p class="m-0 p-0 text-muted small">
            {{ data.item.city_name }}
          </p>
        </template>

        <template #head(aksi)>
          <button
            v-if="detailPengawas.selectsDeleteNaungan.length"
            v-b-tooltip.hover
            class="btn btn-success btn-sm text-white"
            title="Proses"
            @click="prosesUpdateNaungan"
          >
            Proses
          </button>
          <button
            v-if="detailPengawas.selectsDeleteNaungan.length"
            v-b-tooltip.hover
            class="btn btn-info btn-sm text-white"
            title="Proses"
            @click="batalUpdatenaungan"
          >
            Batal
          </button>
          <button
            v-else
            v-b-tooltip.hover
            class="btn btn-danger btn-sm text-white"
            title="Reset Sekolah"
            @click="resetNaungan"
          >
            Reset
          </button>
        </template>

        <template v-slot:cell(aksi)="data">
          <button
            v-b-tooltip.hover
            class="btn btn-danger btn-sm text-white"
            title="Hapus"
            @click="onCartDeleteSchoolUuid(data.item.id)"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
          <button
            v-b-tooltip.hover
            class="btn btn-warning btn-sm text-white"
            title="Pindah Binaan Sekolah"
            @click="onMoveSchoolToOtherPengawas(data.item, data.index)"
          >
            <i class="fas fa-arrows-alt"></i>
          </button>
        </template>
      </b-table>
    </div>

    <Modal
      :onClose="() => (showmodal = false)"
      :show="showmodal"
      title="Tambah Data"
      width="50%"
    >
      <ModalMoveNaungan
        v-if="showmodal"
        from-parent="naunganSchools"
        :onClose="() => (showmodal = false)"
        @close-modal="showmodal = false"
      />
    </Modal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { copyTeksInput } from "@/utils/Api";
import LabelCountData from "@/components/Table/LabelCountData.vue";
import RolesBadge from "@/components/RolesBadge/index.vue";
import Modal from "@/components/Modal.vue";
import EfekLoading from "@/components/Table/EfekLoading.vue";
import Swal from "sweetalert2";
import ModalMoveNaungan from "@/views/Goverment/ManajemenPengawas/Detail/Table/ModalMoveNaungan.vue";

export default {
  name: "TableDetailManajemenPengawas",
  components: {
    ModalMoveNaungan,
    EfekLoading,
    RolesBadge,
    Modal,
    LabelCountData
  },
  data() {
    return {
      fields: [
        {
          key: "no",
          sortable: false,
          width: "10px",
          thStyle: {
            width: "10%"
          }
        },
        {
          label: "Sekolah",
          key: "name",
          sortable: false,
          thStyle: {
            width: "70%"
          }
        },
        {
          key: "aksi",
          sortable: false,
          width: "150px",
          minWidth: "100px",
          thStyle: {
            width: "20%"
          }
        }
      ],
      stickyHeader: true,
      noCollapse: true,
      showmodal: false,
      showmodalAdmin: false
    };
  },
  computed: {
    ...mapState({
      detailPengawas: state => state.manajemenPengawas.data.detailPengawas,
      manajemenPengawas: state => state.manajemenPengawas.data,
      employee: state => state.employee.data
    }),
    ...mapGetters("manajemenPengawas", [
      "nameOfDetailPengawas",
      "rolesOfDetailPengawas",
      "nipOfDetailPengawas",
      "phoneOfDetailPengawas",
      "schoolsOfPengawasGetters"
    ]),

    countNaunganComputed() {
      return this.detailPengawas.schools.length;
    }
  },
  methods: {
    ...mapActions("schools", ["getSchools"]),
    ...mapActions("manajemenPengawas", [
      "getDetailPengawas",
      "getDetailPengawasHaveSchools",
      "prepareSchoolsInPengawasSync",
      "postSyncNaunganSchoolsManajemenPengawas"
    ]),
    ...mapMutations("manajemenPengawas", [
      "changeManajemenPengawasDetailPengawas",
      "changeManajemenPengawasDetailPengawasSelectsDelete"
    ]),
    copyTeksInput,

    async onMoveSchoolToOtherPengawas(item, index) {
      this.showmodal = true;
      this.$store.commit(
        "manajemenPengawas/changeDetailMoveNaunganManajemenPengawas",
        {
          pengawasOldId: this.detailPengawas.detail?.id||0,
          selectPengawasDestination: "",
          schoolIndex: index,
          schoolName: item.name,
          schoolId: item.row_id,
          schoolUuId: item.id
        }
      );
    },

    async onCartDeleteSchoolUuid(uuid) {
      this.changeManajemenPengawasDetailPengawasSelectsDelete(uuid);
    },

    async prosesUpdateNaungan() {
      const resultFormData = await this.prepareSchoolsInPengawasSync();
      const result = await this.postSyncNaunganSchoolsManajemenPengawas({
        employeeId: this.detailPengawas.employeeId,
        formData: resultFormData
      });
      if (result) {
        this.getSchools();
      }
    },

    async batalUpdatenaungan() {
      this.changeManajemenPengawasDetailPengawas({
        selectsDeleteNaungan: []
      });
    },

    async resetNaungan() {
      if (!this.detailPengawas.schools.length) {
        Swal.fire(
          "Pemberitahuan",
          "Pengawas Tidak memiliki Naungan Sekolah",
          "info"
        );
        return;
      }
      Swal.fire({
        title: "Apakah anda yakin ingin Reset Naungan yang ada pada pengawas?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya"
      }).then(async result => {
        if (result.isConfirmed) {
          await this.postSyncNaunganSchoolsManajemenPengawas({
            employeeId: this.detailPengawas.employeeId,
            formData: []
          });
        }
      });
    },

    checkDataEmploye() {
      if (this.manajemenPengawas.items.length) {
        const detailPengawas = this.manajemenPengawas.items.find(
          item => item.id === this.detailPengawas.employeeId
        );
        if (typeof detailPengawas === "object") {
          this.$store.commit(
            "manajemenPengawas/changeManajemenPengawasDetailPengawas",
            {
              detail: detailPengawas,
              schools: detailPengawas.schools ?? []
            }
          );
        }
      } else {
        this.handlerDetailPengawas();
      }
    },

    handlerDetailPengawas() {
      if (!this.detailPengawas.detail.id) {
        this.getDetailPengawas(this.detailPengawas.employeeId);
      }
      this.getDetailPengawasHaveSchools({
        employeeId: this.detailPengawas.employeeId,
        hideMessage: true
      });
    }
  },
  mounted() {
    this.checkDataEmploye();
  },
  watch: {}
};
</script>
<style lang="scss">
@import "@/assets/scss/custom/_rkas-table.scss";
</style>
