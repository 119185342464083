<script>
export default {
  name: "LabelEmpty",
  props: {
    valueEmpty: {
      type: String,
      required: false,
      default: () => "Tidak ditemukan data yang sesuai"
    },
  }
}
</script>

<template>
  <div class="text-center">
      {{ valueEmpty }}
  </div>
</template>

<style scoped lang="scss">

</style>